import { eventChannel } from 'redux-saga';
import { createSelector } from 'reselect';
import {
    delay,
    select,
    call,
    fork,
    put,
    takeEvery,
} from 'typed-redux-saga/macro';
import * as api from '../api';
import * as utils from './utils';
import * as websocketSlice from './websocket';
import * as usernameSlice from './username';

export const NAME = 'room';

const LOCALSTORAGE_PREFIX = 'room_id';

type Users = Record<string, { userId: string; username: string }>;

// Actions
type Action = utils.Union<Actions>;
type Actions = utils.DefineActions<{
    'room/create-new-room': {};
    'room/leave-room': {};

    'room/set-room-id': { roomId: string };
    'room/set-user-id': { userId: string };
    'room/set-users': {
        // { [userId]: username }
        users: Record<string, string>;
    };
    'room/add-user': { userId: string; username: string };
    'room/remove-user': { userId: string };

    'room/clear-room': {};
}>;

export const createNewRoom = (): Action => ({
    type: 'room/create-new-room',
});

export const leaveRoom = (): Action => ({
    type: 'room/leave-room',
});

const setRoomId = (roomId: string): Action => ({
    type: 'room/set-room-id',
    roomId,
});

const setUserId = (userId: string): Action => ({
    type: 'room/set-user-id',
    userId,
});

const setUsers = (users: Record<string, string>): Action => ({
    type: 'room/set-users',
    users,
});
const addUser = (userId: string, username: string): Action => ({
    type: 'room/add-user',
    userId,
    username,
});
const removeUser = (userId: string): Action => ({
    type: 'room/remove-user',
    userId,
});

const clearRoom = (): Action => ({
    type: 'room/clear-room',
});

// State
type State = {
    userId: null | string;
    roomId: null | string;
    users: Users;
};

const defaultState: State = {
    userId: null,
    roomId: null,
    users: {},
};

// Selectors
const getState = (globalState: { [NAME]: State }) => globalState[NAME];
const getUserId = createSelector(getState, ({ userId }) => userId);
const getRoomId = createSelector(getState, ({ roomId }) => roomId);
const getUsers = createSelector(getState, ({ users }) => users);
export const getRoom = createSelector(
    getUserId,
    getRoomId,
    getUsers,
    (userId, roomId, users) => {
        if (userId == null) return null;
        if (roomId == null) return null;
        if (users == null) return null;
        return { userId, roomId, users };
    },
);

// Reducer
export const reducer = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case 'room/set-room-id':
            return { ...state, roomId: action.roomId };

        case 'room/set-user-id':
            return { ...state, userId: action.userId };

        case 'room/set-users': {
            const users: Users = {};
            Object.entries(action.users).forEach(([userId, username]) => {
                users[userId] = { userId, username };
            });
            return { ...state, users };
        }
        case 'room/add-user':
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.userId]: {
                        userId: action.userId,
                        username: action.username,
                    },
                },
            };
        case 'room/remove-user':
            return {
                ...state,
                users: (() => {
                    const clone = { ...state.users };
                    delete clone[action.userId];
                    return clone;
                })(),
            };

        case 'room/clear-room':
            return { ...state, userId: null, roomId: null, users: {} };

        default:
            return state;
    }
};

// Sagas
export function* saga() {
    yield* websocketSlice.takeEveryEvent(function* (event) {
        if (event.type === 'new-room') {
            yield* put(setRoomId(event.roomId));
        }

        if (event.type === 'joined-room') {
            const key = `${LOCALSTORAGE_PREFIX}/${event.roomId}`;
            yield* call([localStorage, 'setItem'], key, event.userId);

            yield* put(setUserId(event.userId));
            yield* put(setUsers(event.users));
            yield* put(addUser(event.userId, event.username));
        }

        if (event.type === 'user-joined') {
            yield* put(addUser(event.userId, event.username));
        }

        if (event.type === 'user-left') {
            yield* put(removeUser(event.userId));
        }
    });

    yield* takeEvery(
        'room/set-room-id',
        function* (action: Actions['room/set-room-id']) {
            const url = `/room/${action.roomId}`;
            if (global.location.pathname !== url) {
                yield* call([global.history, 'pushState'], {}, '', url);
            }

            let username = yield* select(usernameSlice.getUsername);
            while (username == null) {
                // wait for username to be set
                yield* delay(500);
                username = yield* select(usernameSlice.getUsername);
            }

            const userId = yield* select(getUserId);

            let event;
            if (userId == null) {
                event = api.joinRoom(action.roomId, username);
            } else {
                event = api.rejoinRoom(action.roomId, userId, username);
            }
            yield* put(websocketSlice.sendEvent(event));
        },
    );

    yield* takeEvery('room/leave-room', function* () {
        if (global.location.pathname !== '/') {
            yield* call([global.history, 'pushState'], {}, '', '/');
        }

        // If we're in a room, leave
        const room = yield* select(getRoom);
        if (room != null) {
            const event = api.leaveRoom(room.roomId, room.userId);
            yield* put(websocketSlice.sendEvent(event));
            yield* put(clearRoom());
        }
    });

    yield* takeEvery('room/create-new-room', function* () {
        yield* put(websocketSlice.sendEvent(api.createNewRoom()));
    });

    // Route current url
    yield* fork(handleCurrentRoute);

    // Route on url changes
    yield* takeEvery(historyPopEvents(), handleCurrentRoute);
}

function* handleCurrentRoute() {
    const path = global.location.pathname;
    const match = /\/room\/(.+)/.exec(path);
    if (match != null) {
        const roomId = match[1];

        const key = `${LOCALSTORAGE_PREFIX}/${roomId}`;
        const userId = yield* call([localStorage, 'getItem'], key);

        if (userId != null) {
            yield* put(setUserId(userId));
        }

        yield* put(setRoomId(roomId));
    } else {
        yield* put(leaveRoom());
    }
}

function historyPopEvents() {
    return eventChannel<{}>((emitter) => {
        window.onpopstate = () => emitter({});
        return () => {
            window.onpopstate = null;
        };
    });
}
