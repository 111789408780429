import { createSelector } from 'reselect';
import { select, put, takeEvery } from 'typed-redux-saga/macro';
import * as api from '../api';
import * as utils from './utils';
import { takeEveryEvent } from './websocket';
import * as roomSlice from './room';
import * as usernameSlice from './username';
import * as websocketSlice from './websocket';

export const NAME = 'chat';

// Actions
type Action = utils.Union<Actions>;
type Actions = utils.DefineActions<{
    'chat/send-message': { content: string };
    'chat/new-message': { userId: string; username: string; content: string };
}>;

export const sendMessage = (content: string): Action => ({
    type: 'chat/send-message',
    content,
});

const newMessage = ({
    userId,
    username,
    content,
}: {
    userId: string;
    username: string;
    content: string;
}): Action => ({
    type: 'chat/new-message',
    userId,
    username,
    content,
});

// State
type State = {
    messages: {
        key: number;
        userId: string;
        username: string;
        content: string;
    }[];
};

const defaultState: State = {
    messages: [],
};

// Selectors
const getState = (globalState: { [NAME]: State }) => globalState[NAME];
export const getMessages = createSelector(getState, ({ messages }) => messages);

// Reducer
export const reducer = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case 'chat/new-message': {
            const { userId, username, content } = action;
            const message = { key: Math.random(), userId, username, content };
            return {
                ...state,
                messages: [message, ...state.messages],
            };
        }
        default:
            return state;
    }
};

// Sagas
export function* saga() {
    yield* takeEveryEvent(function* (event) {
        if (event.type === 'message-sent') {
            yield* put(
                newMessage({
                    userId: event.userId,
                    username: event.username,
                    content: event.message,
                }),
            );
        }
    });

    yield* takeEvery(
        'chat/send-message',
        function* (action: Actions['chat/send-message']) {
            const room = yield* select(roomSlice.getRoom);
            const username = yield* select(usernameSlice.getUsername);

            if (room == null || username == null) {
                throw new Error(
                    'Attempted to send a message without necessary metadata',
                );
            }

            const { roomId, userId } = room;
            const { content } = action;
            const event = api.sendMessage(roomId, userId, username, content);
            yield* put(websocketSlice.sendEvent(event));
            yield* put(newMessage({ userId, username, content }));
        },
    );
}
