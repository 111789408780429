import { createSelector } from 'reselect';
import { select, put, takeEvery } from 'typed-redux-saga/macro';
import * as api from '../api';
import * as utils from './utils';
import * as websocketSlice from './websocket';
import * as roomSlice from './room';

export const NAME = 'game';

// Actions
type Action = utils.Union<Actions>;
type Actions = utils.DefineActions<{
    'game/set-game-state': { gameState: api.Game };
    'game/play-move': { move: api.Move };
}>;

const setGameState = (gameState: api.Game) => ({
    type: 'game/set-game-state',
    gameState,
});

export const playMove = (move: api.Move): Action => ({
    type: 'game/play-move',
    move,
});

// State
type State = { gameState: null | api.Game };

const defaultState: State = { gameState: null };

// Selectors
const getState = (globalState: { [NAME]: State }) => globalState[NAME];
export const getGameState = createSelector(
    getState,
    ({ gameState }) => gameState,
);

// Reducer
export const reducer = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case 'game/set-game-state':
            return {
                ...state,
                gameState: action.gameState,
            };
        default:
            return state;
    }
};

// Sagas
export function* saga() {
    yield* websocketSlice.takeEveryEvent(function* (event) {
        if (
            event.type === 'new-room' ||
            event.type === 'joined-room' ||
            event.type === 'game-state'
        ) {
            yield* put(setGameState(event.gameState));
        }
    });

    yield* takeEvery(
        'game/play-move',
        function* (action: Actions['game/play-move']) {
            const room = yield* select(roomSlice.getRoom);
            if (room == null) {
                throw new Error(
                    'Attempted to play a move without joining room',
                );
            }
            const { roomId, userId } = room;
            const event = api.gameEvent(roomId, userId, action.move);
            yield* put(websocketSlice.sendEvent(event));
        },
    );
}
