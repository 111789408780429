import { createSelector } from 'reselect';
import { call, fork, put, takeEvery } from 'typed-redux-saga/macro';
import * as utils from './utils';

export const NAME = 'username';

const LOCALSTORAGE_KEY = 'username';

// Actions
type Action = utils.Union<Actions>;
type Actions = utils.DefineActions<{
    'username/set': { username: string };
    'username/clear': {};
}>;

export const setUsername = (username: string): Action => ({
    type: 'username/set',
    username,
});

export const clearUsername = (): Action => ({
    type: 'username/clear',
});

// State
type State = {
    username: null | string;
};

const defaultState: State = {
    username: null,
};

// Selectors
const getState = (globalState: { [NAME]: State }) => globalState[NAME];
export const getUsername = createSelector(getState, ({ username }) => username);

// Reducer
export const reducer = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case 'username/set':
            return { ...state, username: action.username };
        case 'username/clear':
            return { ...state, username: null };
        default:
            return state;
    }
};

// Sagas
export function* saga() {
    yield* fork(function* () {
        const username = yield* call(
            [localStorage, 'getItem'],
            LOCALSTORAGE_KEY,
        );
        if (username != null) {
            yield* put(setUsername(username));
        }
    });

    yield* takeEvery(
        'username/set',
        function* (action: Actions['username/set']) {
            yield* call(
                [localStorage, 'setItem'],
                LOCALSTORAGE_KEY,
                action.username,
            );
        },
    );
}
