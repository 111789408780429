import React, { ReactNode } from 'react';

type FullyCenteredLayoutProps = {
    children: ReactNode;
};
export const CenteredLayout = (props: FullyCenteredLayoutProps) => {
    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="flex flex-col w-1/3 h-1/5">{props.children}</div>
        </div>
    );
};

type ButtonProps = {
    children: ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
};
export const Button = (props: ButtonProps) => {
    const className = props.className ?? '';
    const finalClassName = `border rounded border-black hover:bg-blue-200 ${className}`;
    return (
        <button className={finalClassName} onClick={props.onClick}>
            {props.children}
        </button>
    );
};

type SpinnerProps = {
    className?: string;
};
export const Spinner = (props: SpinnerProps) => {
    const className = props.className ?? '';
    const finalClassName = `animate-spin stroke-current ${className}`;
    return (
        <svg className={finalClassName} viewBox="0 0 40 40">
            <circle
                cx="20"
                cy="20"
                r="17"
                strokeWidth="5"
                strokeLinecap="round"
                strokeDasharray="62.8"
                fill="transparent"
            />
        </svg>
    );
};
