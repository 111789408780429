import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as store from '../store';
import { CenteredLayout, Button } from './components';

const MIN_USERNAME_LEN = 3;

export default function PagePickUsername() {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setInput(value);
        setIsValid(value.trim().length >= MIN_USERNAME_LEN);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (isValid) {
            const username = input.trim();
            dispatch(store.username.setUsername(username));
        } else {
            setShowError(true);
        }
    };

    return (
        <CenteredLayout>
            <h1 className="text-3xl">Pick a Username</h1>
            <form className="mt-2 w-full flex" onSubmit={onSubmit}>
                <input
                    className="mr-2 border border-black rounded flex-grow"
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={onChange}
                />
                <Button className="w-14">Ok</Button>
            </form>
            {showError && (
                <div className="mt-2 text-sm text-red-400">
                    (must be at least {MIN_USERNAME_LEN} characters long)
                </div>
            )}
        </CenteredLayout>
    );
}
