import { useDispatch } from 'react-redux';
import * as store from '../store';
import { Game, Outcome, OuterCell, Piece } from '../api/ultimate-tic-tac-toe';

export default function UltimateTicTacToe({ game }: { game: Game }) {
    const dispatch = useDispatch();

    return (
        <div className="p-6 w-full h-full flex justify-center items-center">
            <OuterGrid
                grid={game}
                style={{ width: 'min(90vh, 80vw)', height: 'min(90vh, 80vw)' }}
                onClick={(outer, inner) =>
                    dispatch(store.game.playMove([outer, inner]))
                }
            />
        </div>
    );
}

const gridBorderStyle = (i: number) => ({
    borderTopWidth: i !== 0 && i !== 1 && i !== 2 ? '2px' : 0,
    borderLeftWidth: i !== 0 && i !== 3 && i !== 6 ? '2px' : 0,
    borderBottomWidth: i !== 6 && i !== 7 && i !== 8 ? '2px' : 0,
    borderRightWidth: i !== 2 && i !== 5 && i !== 8 ? '2px' : 0,
});

const OuterGrid = ({
    grid,
    style,
    onClick,
}: {
    grid: Game;
    style?: Record<string, any>;
    onClick?: (outerIdx: number, innerIdx: number) => void;
}) => {
    const { outcome } = grid;
    return (
        <div className="grid grid-cols-3 grid-rows-3" style={style}>
            {grid.cells.map((outer, outerIdx) => {
                const classes = [
                    'p-5 relative grid grid-cols-3 grid-rows-3 border-black',
                ];
                if (
                    outcome?.type === 'win' &&
                    outcome.triple.includes(outerIdx)
                ) {
                    if (pieceColor(outcome.piece) === 'blue') {
                        classes.push('bg-blue-300');
                    } else {
                        classes.push('bg-red-300');
                    }
                } else if (grid.active === outerIdx) {
                    classes.push('bg-green-100');
                }
                return (
                    <div
                        key={outerIdx}
                        style={gridBorderStyle(outerIdx)}
                        className={classes.join(' ')}
                    >
                        <Overlay outcome={outer.outcome} />
                        <InnerGrid
                            grid={outer}
                            onClick={(innerIdx) =>
                                onClick?.(outerIdx, innerIdx)
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};

const Overlay = ({ outcome }: { outcome: null | Outcome }) => {
    if (outcome == null) return null;

    const classes = ['p-5 absolute w-full h-full bg-opacity-70 opacity-90'];

    if (outcome.type === 'draw') {
        classes.push('bg-gray-400');
        return <div className={classes.join(' ')} />;
    }

    const color = pieceColor(outcome.piece);
    if (color === 'blue') {
        classes.push('bg-blue-100');
    } else if (color === 'red') {
        classes.push('bg-red-100');
    }
    return (
        <div className={classes.join(' ')}>
            {outcome.piece === 'x' && <X />}
            {outcome.piece === 'o' && <O />}
        </div>
    );
};

const InnerGrid = ({
    grid,
    onClick,
}: {
    grid: OuterCell;
    onClick?: (inner: number) => void;
}) => {
    const { cells, outcome } = grid;
    return (
        <>
            {cells.map((inner, innerIdx) => {
                const classes = [
                    'border-black p-2',
                    inner == null ? 'hover:bg-yellow-100' : '',
                ];
                if (
                    outcome?.type === 'win' &&
                    outcome.triple.includes(innerIdx)
                ) {
                    const color = pieceColor(outcome.piece);
                    if (color === 'blue') {
                        classes.push('bg-blue-300');
                    } else {
                        classes.push('bg-red-300');
                    }
                }
                return (
                    <div
                        key={innerIdx}
                        style={gridBorderStyle(innerIdx)}
                        className={classes.join(' ')}
                        onClick={() => onClick?.(innerIdx)}
                    >
                        {inner === 'x' && <X />}
                        {inner === 'o' && <O />}
                    </div>
                );
            })}
        </>
    );
};

const pieceColor = (piece: Piece) => (piece === 'x' ? 'blue' : 'red');

const X = () => (
    <svg className="fill-current text-blue-700" viewBox="0 0 100 100">
        <path d="M 100,0 59.463724,49.429148 99.921137,100 H 81.861201 L 49.842271,58.831431 17.0347,100 H 0 L 40.9306,50.033579 0.94637224,0 H 18.927445 L 50.552054,40.631296 82.886436,0 Z" />
    </svg>
);

const O = () => (
    <svg className="fill-current text-red-600" viewBox="0 0 100 100">
        <path d="M 50,0 C 22.385763,0 0,22.385763 0,50 0,77.614237 22.385763,100 50,100 77.614237,100 100,77.614237 100,50 100,22.385763 77.614237,0 50,0 Z m 0,13 C 70.98682,13 87,29.01318 87,50 87,70.98682 70.98682,87 50,87 29.01318,87 13,70.98682 13,50 13,29.01318 29.01318,13 50,13 Z" />
    </svg>
);
