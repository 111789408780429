// Solution for assigning each user a unique identifier that will be the same
// regardless of disconnects and devices

// Takes any string and turns it into a CSS color (hsl format)
export const colorHash = (string: string) => {
    if (colorHashCache[string] == null) {
        colorHashCache[string] = intToColor(cyrb53(string));
    }
    return colorHashCache[string];
};
const colorHashCache: Record<string, string> = {};

// src: https://stackoverflow.com/a/52171480
const cyrb53 = (string: string) => {
    let h1 = 0xdeadbeef,
        h2 = 0x41c6ce57;
    for (let i = 0, ch; i < string.length; i++) {
        ch = string.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
        Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
        Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
        Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
        Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const intToColor = (int: number) => {
    const bigInt = BigInt(int);

    const lowBits = Number(bigInt & BigInt(0xffff));
    const midBits = Number((bigInt >> BigInt(16)) & BigInt(0xffff));
    const highBits = Number((bigInt >> BigInt(32)) & BigInt(0xffff));

    const l = rngToInt(60, 80, lowBits / 0xffff);
    const c = rngToInt(40, 130, midBits / 0xffff);
    const h = rngToInt(0, 360, highBits / 0xffff);

    const [r, g, b] = lchToRgb(l, c, h);

    return `rgb(${r}, ${g}, ${b})`;
};

// src: http://www.easyrgb.com/en/math.php
type Triple = [number, number, number];
const lchToRgb = (L: number, C: number, H: number): Triple => {
    // CIE-LCH => CIE-Lab
    const a = Math.cos(degreeToRadian(H)) * C;
    const b = Math.sin(degreeToRadian(H)) * C;

    // CIE-Lab => XYZ (D65 / 2 deg)
    let Y = (L + 16) / 116;
    let X = a / 500 + Y;
    let Z = Y - b / 200;

    Y = Y ** 3 > 0.008856 ? Y ** 3 : (Y - 0.1379) / 7.787;
    X = X ** 3 > 0.008856 ? X ** 3 : (X - 0.1379) / 7.787;
    Z = Z ** 3 > 0.008856 ? Z ** 3 : (Z - 0.1379) / 7.787;

    X *= 95.047;
    Y *= 100;
    Z *= 108.883;

    // XYZ => Adobe-RGB
    X /= 100;
    Y /= 100;
    Z /= 100;

    let R = X * 2.04137 + Y * -0.56495 + Z * -0.34469;
    let G = X * -0.96927 + Y * 1.87601 + Z * 0.04156;
    let B = X * 0.01345 + Y * -0.11839 + Z * 1.01541;

    R = R < 0 ? 0 : R ** (1 / 2.19921875);
    G = G < 0 ? 0 : G ** (1 / 2.19921875);
    B = B < 0 ? 0 : B ** (1 / 2.19921875);

    const aR = Math.round(R * 255);
    const aG = Math.round(G * 255);
    const aB = Math.round(B * 255);

    return [aR, aG, aB];
};

const degreeToRadian = (deg: number) => deg * (Math.PI / 180);

// min and max are both inclusive
const rngToInt = (min: number, max: number, value: number) =>
    Math.floor(value * (max - min + 1) + min);
