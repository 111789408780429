import * as t from 'io-ts';

import { Game, Move } from './ultimate-tic-tac-toe';

export { Game, Move } from './ultimate-tic-tac-toe';

/***************************************************************************************************
 * Outgoing Events
 */

export type Outgoing =
    | { type: 'create-new-room' }
    | { type: 'join-room'; roomId: string; username: string }
    | { type: 'rejoin-room'; roomId: string; userId: string; username: string }
    | { type: 'leave-room'; roomId: string; userId: string }
    | {
          type: 'send-message';
          roomId: string;
          userId: string;
          username: string;
          message: string;
      }
    | {
          type: 'game-event';
          roomId: string;
          userId: string;
          event: Move;
      };

export const createNewRoom = (): Outgoing => ({
    type: 'create-new-room',
});

export const joinRoom = (roomId: string, username: string): Outgoing => ({
    type: 'join-room',
    roomId,
    username,
});

export const rejoinRoom = (
    roomId: string,
    userId: string,
    username: string,
): Outgoing => ({
    type: 'rejoin-room',
    roomId,
    userId,
    username,
});

export const leaveRoom = (roomId: string, userId: string): Outgoing => ({
    type: 'leave-room',
    roomId,
    userId,
});

export const sendMessage = (
    roomId: string,
    userId: string,
    username: string,
    message: string,
): Outgoing => ({
    type: 'send-message',
    roomId,
    userId,
    username,
    message,
});

export const gameEvent = (
    roomId: string,
    userId: string,
    event: Move,
): Outgoing => ({
    type: 'game-event',
    roomId,
    userId,
    event,
});

/***************************************************************************************************
 * Incoming Events
 */

type IncomingUnknown = { type: 'UNKNOWN'; event: unknown };
export type Incoming = t.TypeOf<typeof Incoming> | IncomingUnknown;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Incoming = t.union([
    t.type({
        type: t.literal('new-room'),
        roomId: t.string,
        gameState: Game,
    }),

    t.type({
        type: t.literal('joined-room'),
        roomId: t.string,
        userId: t.string,
        username: t.string,
        users: t.record(t.string, t.string),
        gameState: Game,
    }),

    t.type({
        type: t.literal('user-joined'),
        roomId: t.string,
        userId: t.string,
        username: t.string,
    }),

    t.type({
        type: t.literal('user-left'),
        roomId: t.string,
        userId: t.string,
    }),

    t.type({
        type: t.literal('message-sent'),
        roomId: t.string,
        userId: t.string,
        username: t.string,
        message: t.string,
    }),

    t.type({
        type: t.literal('game-state'),
        roomId: t.string,
        gameState: Game,
    }),
]);

export const parseIncoming = (event: unknown): Incoming => {
    if (Incoming.is(event)) {
        return event;
    }
    return { type: 'UNKNOWN', event };
};
