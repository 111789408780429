import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as store from '../store';
import { Button } from './components';
import { colorHash } from './color-hash';
import UltimateTicTacToe from './game-ultimate-tic-tac-toe';

export default function PageGame() {
    return (
        <div className="w-screen h-screen flex">
            <Game className="flex-auto" />
            <ChatBar className="w-1/4" />
        </div>
    );
}

const Game = (props: { className: string }) => {
    const gameState = useSelector(store.game.getGameState);

    const className = `flex justify-center items-center ${props.className}`;
    return (
        <div className={className}>
            {!gameState && <div>Loading...</div>}
            {gameState && <UltimateTicTacToe game={gameState} />}
        </div>
    );
};

const ChatBar = (props: { className: string }) => {
    const className = `flex flex-col border-l border-gray-300 ${props.className}`;
    return (
        <div className={className}>
            <ChatBarHeader className="flex-initial" />
            <ChatBarMessages className="flex-auto" />
            <ChatBarInput className="flex-initial" />
        </div>
    );
};

const ChatBarHeader = (props: { className: string }) => {
    const dispatch = useDispatch();
    const room = useSelector(store.room.getRoom);

    const className = `flex px-3 py-2 bg-gray-200 border-b border-gray-300 ${props.className}`;
    return (
        <div className={className}>
            <div className="flex-auto">
                {Object.values(room?.users ?? {}).map(
                    ({ userId, username }) => (
                        <div
                            key={userId}
                            style={{ color: colorHash(userId) }}
                            className="font-bold"
                        >
                            {username}
                        </div>
                    ),
                )}
            </div>
            <div className="flex-initial">
                <Button
                    className="px-2 flex-initial"
                    onClick={() => dispatch(store.room.leaveRoom())}
                >
                    Home
                </Button>
            </div>
        </div>
    );
};

const ChatBarMessages = (props: { className: string }) => {
    const chatMsgs = useSelector(store.chat.getMessages);

    const className = `flex flex-col-reverse px-3 py-2 bg-gray-100 overflow-y-scroll ${props.className}`;
    return (
        <div className={className}>
            {Object.values(chatMsgs).map(
                ({ key, userId, username, content }) => (
                    <div key={key}>
                        <span
                            style={{ color: colorHash(userId) }}
                            className="font-bold"
                        >
                            {username}
                        </span>
                        : {content}
                    </div>
                ),
            )}
        </div>
    );
};

const ChatBarInput = (props: { className: string }) => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [input, setInput] = useState('');

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInput(e.currentTarget.value);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(store.chat.sendMessage(input));
        setInput('');
    };

    const className = `bg-gray-200 p-2 border-t border-gray-300 ${props.className}`;
    return (
        <div className={className}>
            <form className="w-full flex" onSubmit={onSubmit}>
                <input
                    className="mr-1 px-1 border border-black rounded flex-grow"
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={onChange}
                />
                <Button className="w-14 text-sm">Send</Button>
            </form>
        </div>
    );
};
