import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'typed-redux-saga/macro';

import * as chat from './chat';
import * as game from './game';
import * as room from './room';
import * as username from './username';
import * as websocket from './websocket';

export * as chat from './chat';
export * as game from './game';
export * as room from './room';
export * as username from './username';
export * as websocket from './websocket';

// Reducer
const reducer = redux.combineReducers({
    [chat.NAME]: chat.reducer,
    [game.NAME]: game.reducer,
    [room.NAME]: room.reducer,
    [username.NAME]: username.reducer,
    [websocket.NAME]: websocket.reducer,
});

// Sagas
function* rootSaga() {
    yield* spawn(chat.saga);
    yield* spawn(game.saga);
    yield* spawn(room.saga);
    yield* spawn(username.saga);
    yield* spawn(websocket.saga);
}

// Middleware
const logger: redux.Middleware = (store) => (next) => (action) => {
    console.groupCollapsed('REDUX:', action.type);
    console.info('@ction:', action);
    const result = next(action);
    console.info('$tore:', store.getState());
    console.groupEnd();
    return result;
};

const sagasMiddleware = createSagaMiddleware();

const store = redux.createStore(
    reducer,
    redux.applyMiddleware(logger, sagasMiddleware),
);

const runSagas = () => sagasMiddleware.run(rootSaga);

export default Object.assign(store, { runSagas });
