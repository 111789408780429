import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as store from '../store';
import { CenteredLayout, Button } from './components';

export default function PageHome() {
    const dispatch = useDispatch();
    const username = useSelector(store.username.getUsername);

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(store.room.createNewRoom());
    };

    return (
        <CenteredLayout>
            <h1 className="text-3xl flex items-center">
                Welcome {username}!
                <Button
                    className="m-2 px-1 text-xs bg-gray-200 border-gray-200 text-gray-800"
                    onClick={() => dispatch(store.username.clearUsername())}
                >
                    (change)
                </Button>
            </h1>
            <div>
                <Button className="mt-2 px-1" onClick={onClick}>
                    Create a new Ultimate-Tic-Tac-Toe game!
                </Button>
            </div>
        </CenteredLayout>
    );
}
