import { eventChannel } from 'redux-saga';
import { createSelector } from 'reselect';
import {
    call,
    delay,
    fork,
    put,
    select,
    takeEvery,
} from 'typed-redux-saga/macro';
import * as utils from './utils';
import * as api from '../api';

export const NAME = 'websocket';

const URI =
    window.location.hostname === 'localhost'
        ? 'ws://localhost:8080'
        : `wss://${window.location.host}/ws`;

type WSStatus = 'Connecting' | 'Open' | 'Closed';

// Actions
type Action = utils.Union<Actions>;
type Actions = utils.DefineActions<{
    'websocket/set-status': { status: WSStatus };
    'websocket/send-event': { event: api.Outgoing };
    'websocket/receive-event': { event: api.Incoming };
}>;

const setStatus = (status: WSStatus): Action => ({
    type: 'websocket/set-status',
    status,
});

const receiveEvent = (event: api.Incoming): Action => ({
    type: 'websocket/receive-event',
    event,
});

export const sendEvent = (event: api.Outgoing): Action => ({
    type: 'websocket/send-event',
    event,
});

// State
type State = {
    status: WSStatus;
};

const defaultState: State = {
    status: 'Closed',
};

// Selectors
const getState = (globalState: { [NAME]: State }) => globalState[NAME];
export const getStatus = createSelector(getState, ({ status }) => status);

// Reducer
export const reducer = (state = defaultState, action: Action): State => {
    switch (action.type) {
        case 'websocket/set-status':
            return { ...state, status: action.status };
        default:
            return state;
    }
};

// Sagas
export function* saga() {
    const { channel, send, connect } = websocket();

    yield* takeEvery(
        'websocket/send-event',
        function* (action: Actions['websocket/send-event']) {
            // Wait until connected before sending message
            while ((yield* select(getStatus)) !== 'Open') {
                yield* delay(1000);
            }

            yield* call(send, JSON.stringify(action.event));
        },
    );

    yield* takeEvery(channel, function* (event: WebSocketEvent) {
        switch (event.type) {
            case 'status':
                yield* put(setStatus(event.status));
                break;
            case 'message': {
                let data;
                try {
                    data = JSON.parse(event.message);
                } catch {
                    data = event.message;
                }
                yield* put(receiveEvent(api.parseIncoming(data)));
                break;
            }
        }
    });

    // reconnect on disconnection
    yield* takeEvery(
        'websocket/set-status',
        function* (action: Actions['websocket/set-status']) {
            if (action.status === 'Closed') {
                yield* call(connect);
            }
        },
    );

    // connect on start up
    yield* call(connect);
}

type WebSocketEvent =
    | { type: 'message'; message: string }
    | { type: 'status'; status: WSStatus };
function websocket() {
    let emitter: (event: WebSocketEvent) => void;
    const channel = eventChannel<WebSocketEvent>((emitter_) => {
        emitter = emitter_;
        return () => {};
    });

    let ws: WebSocket;
    const connect = () => {
        emitter({ type: 'status', status: 'Connecting' });
        ws = new WebSocket(URI);
        ws.onopen = () => emitter({ type: 'status', status: 'Open' });
        ws.onclose = () => emitter({ type: 'status', status: 'Closed' });
        ws.onmessage = (msg) => emitter({ type: 'message', message: msg.data });
    };
    const send = (msg: string) => {
        if (ws?.readyState === WebSocket.OPEN) {
            ws.send(msg);
        }
    };

    return { channel, send, connect };
}

export function* takeEveryEvent(worker: (event: api.Incoming) => unknown) {
    yield* takeEvery(
        'websocket/receive-event',
        function* (action: Actions['websocket/receive-event']) {
            yield fork(worker, action.event);
        },
    );
}
