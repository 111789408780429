/* eslint-disable @typescript-eslint/no-redeclare */

import * as t from 'io-ts';

const nullable = <C extends t.Any>(codec: C) => t.union([t.null, codec]);

/***************************************************************************************************
 * Game State Codecs
 */

export type Coord = t.TypeOf<typeof Coord>;
export const Coord = t.number;

export type Piece = t.TypeOf<typeof Piece>;
export const Piece = t.union([t.literal('o'), t.literal('x')]);

export type Outcome = t.TypeOf<typeof Outcome>;
export const Outcome = t.union([
    t.type({ type: t.literal('draw') }),
    t.type({
        type: t.literal('win'),
        piece: Piece,
        triple: t.tuple([Coord, Coord, Coord]),
    }),
]);

export type OuterCell = t.TypeOf<typeof OuterCell>;
export const OuterCell = t.type({
    cells: t.array(nullable(Piece)),
    outcome: nullable(Outcome),
});

export type Game = t.TypeOf<typeof Game>;
export const Game = t.type({
    playerX: nullable(t.string),
    playerO: nullable(t.string),
    turn: Piece,
    active: nullable(Coord),
    cells: t.array(OuterCell),
    outcome: nullable(Outcome),
});

export type Move = t.TypeOf<typeof Move>;
export const Move = t.tuple([Coord, Coord]);
