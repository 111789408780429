import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as store from '../store';

import PageHome from './page-home';
import PageGame from './page-game';
import PagePickUsername from './page-pick-username';
import { Spinner } from './components';

export default function App() {
    return (
        <>
            <WebsocketStateNotification />
            <Page />
        </>
    );
}

const Page = () => {
    const username = useSelector(store.username.getUsername);
    const room = useSelector(store.room.getRoom);

    if (username == null) {
        return <PagePickUsername />;
    }

    if (room != null) {
        return <PageGame />;
    }

    return <PageHome />;
};

const WebsocketStateNotification = () => {
    const [isFirstConnect, setIsFirstConnect] = useState(true);
    const state = useSelector(store.websocket.getStatus);

    useEffect(() => {
        if (state === 'Open') {
            setIsFirstConnect(false);
        }
    }, [state, setIsFirstConnect]);

    const classes = ['absolute top-1 left-1 border-2 rounded flex p-1'];
    switch (state) {
        case 'Closed': {
            classes.push('border-red-400 bg-red-200');
            return <div className={classes.join(' ')}>Disconnected</div>;
        }
        case 'Connecting': {
            classes.push(
                isFirstConnect
                    ? 'border-blue-400 bg-blue-200'
                    : 'border-yellow-400 bg-yellow-200',
            );
            return (
                <div className={classes.join(' ')}>
                    {isFirstConnect ? 'Connecting' : 'Reconnecting'}
                    <Spinner className="h-6 w-6 ml-2" />
                </div>
            );
        }
        default:
            return null;
    }
};
